:root {
    --primaryColor: #da1e1b;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Open Sans", sans-serif;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    -webkit-tap-highlight-color: transparent;
}

.container {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
}

a,
button {
    display: inline-block;
    font: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

img {
    width: 100%;
}

/* Text Styles */

.title {
    font-size: 3rem;
    font-weight: 300;
}

.heading {
    font-size: 2.25rem;
    margin-bottom: 20px;
}

.heading::after {
    display: block;
    margin: 10px auto 0;
    content: "";
    width: 50px;
    height: 4px;
    background-color: var(--primaryColor);
}

.sub-heading {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0 0 20px;
}

.para-text {
    font-size: 1.125rem;
}

/* Navbar Styles */

header {
    position: fixed;
    width: 100%;
    background: var(--primaryColor);
    padding: 10px 0;
    z-index: 999;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
}

.nav-logo {
    width: 50px;
}

.nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px 50px;
    color: #fff;
}

.nav-list.hide li:nth-child(n + 2) {
    display: none;
}

.nav-link::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    margin-top: 3px;
    background: #fff;
    transition: all 0.5s;
}

.nav-link:hover:after {
    width: 100%;
}

/* Hamburger Styles */

.hamburger {
    display: none;
}

.hamburger {
    width: 30px;
    height: 25px;
    margin-top: 10px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
    top: 0px;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
    top: 9px;
}

.hamburger span:nth-child(4) {
    top: 18px;
}

.hamburger.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/* Hero Section Styles */

.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee;
}

.hero-img {
    max-width: 500px;
}

/* variant Section Styles */

.variant-section {
    padding: 75px 0;
}

.corona-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 50px 100px;
    margin-top: 50px;
}

.corona-variant-img img {
    width: 225px;
    height: 225px;
}

.variant-name {
    font-family: "Fredoka One", cursive;
    font-size: 1.75rem;
    font-weight: 700;
    margin-top: 25px;
    letter-spacing: 2px;
}

.variant-origin {
    margin-top: 10px;
    font-weight: 600;
}

.btn {
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    padding: 10px 25px;
    border: none;
    border-radius: 160px;
    -webkit-border-radius: 160px;
    -moz-border-radius: 160px;
    -ms-border-radius: 160px;
    -o-border-radius: 160px;
    margin-top: 20px;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
}

.btn:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

/* Infect Section */

.infect-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee;
    --selectedVarientColor: #da1e1b;
}

.infect-btn {
    font-size: 1.25rem;
    background-color: var(--selectedVarientColor);
    margin-top: 20px;
}

.infect-title {
    font-size: 4rem;
    font-weight: 300;
}

.infect-sub-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 20px;
}

/* Disclaimer Section */

.disclaimer-section {
    padding: 75px 0;
}

/* Footer Styles */

footer {
    padding: 100px 0 50px;
    background-color: #222222;
    color: #fff;
}

.footer-content .para-text {
    margin: 10px;
}

.footer-content .vaccinate,
.footer-content .vaccinate-2 {
    font-size: 0.8rem;
    margin-top: 25px;
    font-weight: 700;
    letter-spacing: 2px;
}

.footer-content .vaccinate-2 {
    opacity: 0.15;
    margin-top: 50px;
}

.social-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px 100px;
    margin-top: 50px;
}

.social-list img {
    width: 40px;
}

.social-list p {
    margin-top: 10px;
}

.copyright-text {
    margin-top: 50px;
}

/* Loading */

.loading-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.corona-bg-img {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 170px;
    opacity: 0.25;
    z-index: 9;
    animation: corona 1s infinite ease-in-out;
    -webkit-animation: corona 1s infinite ease-in-out;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.loading-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--selectedVarientColor);
    opacity: 1;
    z-index: 99;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.progress-ring__circle {
    visibility: hidden;
    stroke: var(--selectedVarientColor);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.progress-ring__circle.animate {
    visibility: visible;
    transition: 0.35s stroke-dashoffset;
}

@keyframes corona {
    0% {
        opacity: 0.25;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.25;
    }
}

/* Media Queries */

@media (max-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 960px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 720px;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 540px;
    }

    /* Navbar Styles */

    .hamburger {
        display: block;
    }

    .nav-list {
        flex-direction: column;
        position: absolute;
        top: -500%;
        left: 0;
        width: 100%;
        padding: 15px 0;
        border-top: 2px solid #fff;
        background-color: var(--primaryColor);
        transition: top 0.75s;
        -webkit-transition: top 0.75s;
        -moz-transition: top 0.75s;
        -ms-transition: top 0.75s;
        -o-transition: top 0.75s;
    }

    .nav-list.open {
        top: 100%;
    }

    /* Footer Styles */

    .social-list {
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 100%;
    }
}
